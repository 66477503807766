import React from "react"
import Layout from "../components/Layout"
import styled from "styled-components"
import Title from "../components/Title"
import StyledHero from "../components/StyledHero"
import { graphql } from "gatsby"
import SEO from "../components/SEO"
import "bootstrap/dist/css/bootstrap.min.css"

const Index = ({ data }) => {
  return (
    <Layout>
      <SEO
        title="Datenschutz"
        description={data.site.siteMetadata.description}
      />

      <StyledHero img={data.geschichteImage.childImageSharp.fluid}></StyledHero>

      <ServicesContainer>
        <Title title="Datenschutzerklärung " subtitle="" />
        <hr className="services_separator" />
        <TextWrapper>
          <p>
            Für uns als Betreiber dieser Website hat der verantwortungsbewusste
            Umgang mit personenbezogenen Daten hohe Priorität. So möchten wir,
            dass Sie wissen, wie wir mit Ihren Daten von der Erhebung bis zur
            Verwendung umgehen. Wir verwenden zur Sicherheit Ihrer Daten bei
            einer Übertragung an uns dem aktuellen Stand der Technik
            entsprechende Verschlüsselungsverfahren über HTTPS. Über die Website
            beziehen wir keine personenrelevanten Daten. Sollten Sie uns jedoch
            eine Informations- oder sonstige Anfrage zusenden, so werden Ihre
            uns nach Ihrer freien Entscheidung zugesendeten persönlichen Daten
            nur für die Beantwortung der Anfrage verwendet. Die Daten werden in
            Papierform oder elektronischer Form gemäß den für die Aufbewahrung
            von Schriftgut geltenden Fristen aufbewahrt. Die Verwendung Ihrer
            Daten erfolgt ausschließlich für die unmittelbare Korrespondenz mit
            Ihnen, es erfolgt keine Weitergabe an Dritte.
          </p>
          <h5>VELUX Dachfenster-Konfigurator</h5>
          <p>
            Beschreibung und Umfang der Datenverarbeitung bei Nutzung des VELUX
            Konfigurators Mit dem VELUX Dachfenster-Konfigurator, der per IFrame
            auf dieser Webseite eingebunden ist, können Sie in mehreren
            Schritten eine persönliche Dachfensterlösung konfigurieren, eine
            unverbindliche Indikation für einen Gesamtpreis aus Produkten und
            ggf. den Einbaukosten ermitteln und auf Wunsch für die
            abgeschlossene Konfiguration ein unverbindliches Angebot vom
            Betreiber dieser Webseite anfordern. Dazu wird, Ihre Zustimmung
            vorausgesetzt, aus dem VELUX-System eine E-Mail an den ausgewählten
            Handwerksbetrieb (den Betreiber dieser Webseite) sowie an Sie selbst
            gesendet. Diese enthält die ausgewählte Produkt-Konfiguration sowie
            folgende personenbezogene Daten:
          </p>
          <ul>
            <li>Vor- und Nachname</li>
            <li>Anschrift</li>
            <li>E-Mail-Adresse</li>
            <li>Telefonnummer</li>
            <li>Projektinformationen</li>
          </ul>
          <p>
            Im Zeitpunkt der Absendung der Nachricht werden zusätzlich folgende
            Daten erhoben: Datum und Uhrzeit Ihre Daten werden im System der
            VELUX Deutschland GmbH gespeichert.
          </p>
          <p>
            Im Rahmen der Nutzung des Konfigurators werden Ihre Daten gemäß den
            Nutzungsbedingungen
            [<a href="https://www.velux.de/ueber-velux/nutzungsbestimmungen-partnerprogramm">https://www.velux.de/ueber-velux/nutzungsbestimmungen-partnerprogramm</a>]
            und der geltenden Datenschutzerklärung von VELUX
            [<a href="https://www.velux.de/ueber-velux/datenschutz">https://www.velux.de/ueber-velux/datenschutz</a>] behandelt. Für die
            Verarbeitung der Daten wird im Rahmen des Absendevorgangs Ihre
            Einwilligung dazu eingeholt. Im weiteren Verlauf erhält VELUX vom
            Betreiber dieser Webseite eine Rückmeldung zum Bearbeitungsstand
            Ihrer Anfrage. Dies ist notwendig, damit VELUX Sie ggf. zeitnah bei
            den ausgelobten Dienstleistung unterstützen kann. Ebenso ist für die
            Erbringung dieses Services Ihr Einverständnis notwendig, dass VELUX
            und der Handwerksbetrieb Sie dazu telefonisch oder per Mail
            kontaktieren darf. Die Rechtsgrundlage für die Verarbeitung der oben
            genannten Daten ist Art. 6 Abs. 1 lit. b DSGVO. (Erfüllung eines
            Vertrages; Durchführung vorvertraglicher Maßnahmen/Beantwortung von
            Anfragen). Soweit vom Betreiber dieser Webseite ein Angebot
            angefordert wurde oder ein Auftrag erteilt wurde, gilt die
            Datenschutzerklärung dieser Webseite.
          </p>
          <h5>Kontaktformular</h5>
          <p>
            Um Ihnen die Funktionalität des Kontaktformulars zur Verfügung zu
            stellen, senden wir die von Ihnen angegebene E-Mail-Adresse und den
            Namen an Formspree Inc. - sobald sie das Kontaktformular absenden.
            Diese Daten werden dort verarbeitet, gespeichert und an uns per Mail
            weitergegeben. Außerdem werden von Formspree unter anderem weitere
            Daten wie Ihre IP-Adresse, Ihr Typ des Browsers, die Domain der
            Webseite, das Datum und die Zeit des Zugriffs erhoben, um die
            gewünschte Funktionalität des Kontaktformulars bereitzustellen. Eine
            detaillierte Übersicht der erhobenen Daten finden Sie hier
            http://docs.formspree.io/files/terms.pdf. Rechtsgrundlage für die
            Nutzung von Formspree ist Art. 6 Abs. 1 S. 1 lit. f DS-GVO
            (berechtigtes Interesse). In dem vorliegenden Fall werden
            personenbezogene Daten in die USA übertragen werden: Die
            Verarbeitung, Weitergabe und Speicherung der Daten ist von Formspree
            in dem folgenden Dokument zusammengefasst:
            http://docs.formspree.io/files/terms.pdf. Anstatt des
            Kontaktformulars können Sie uns natürlich auch direkt per E-Mail
            kontaktieren.
          </p>
          <h5>Google Maps</h5>
          <p>
            Diese Webseite nutzt Google Maps zur Darstellung interaktiver Karten
            und zur Erstellung von Anfahrtsbeschreibungen. Google Maps ist ein
            Kartendienst von Google Inc., 1600 Amphitheatre Parkway, Mountain
            View, California 94043, USA. Durch die Nutzung von Google Maps
            können Informationen über die Benutzung dieser Website
            einschließlich Ihrer IP-Adresse und der im Rahmen der
            Routenplanerfunktion eingegebenen (Start-) Adresse an Google in den
            USA übertragen werden. Wenn Sie eine Webseite unseres
            Internetauftritts aufrufen, die Google Maps enthält, baut Ihr
            Browser eine direkte Verbindung mit den Servern von Google auf. Der
            Karteninhalt wird von Google direkt an Ihren Browser übermittelt und
            von diesem in die Webseite eingebunden. Daher haben wir keinen
            Einfluss auf den Umfang der auf diese Weise von Google erhobenen
            Daten. Entsprechend unserem Kenntnisstand sind dies zumindest
            folgende Daten:
          </p>
          <p>
            <ul>
              <li>
                {" "}
                Datum und Uhrzeit des Besuchs auf der betreffenden Webseite,
              </li>{" "}
              <li> Internetadresse oder URL der aufgerufenen Webseite,</li>{" "}
              <li>
                IP-Adresse, im Rahmen der Routenplanung eingegebene
                (Start-)Anschrift.{" "}
              </li>
            </ul>
          </p>
          <p>
            Auf die weitere Verarbeitung und Nutzung der Daten durch Google
            haben wir keinen Einfluss und können daher hierfür keine
            Verantwortung übernehmen. Wenn Sie nicht möchten, dass Google über
            unseren Internetauftritt Daten über Sie erhebt, verarbeitet oder
            nutzt, können Sie in Ihrem Browsereinstellungen JavaScript
            deaktivieren. In diesem Fall können Sie die Kartenanzeige jedoch
            nicht nutzen. Zweck und Umfang der Datenerhebung und die weitere
            Verarbeitung und Nutzung der Daten durch Google sowie Ihre
            diesbezüglichen Rechte und Einstellungsmöglichkeiten zum Schutz
            Ihrer Privatsphäre entnehmen Sie bitte den Datenschutzhinweisen von
            Google (https://policies.google.com/privacy?hl=de). Durch die
            Nutzung unserer Webseite erklären Sie sich mit der Bearbeitung der
            über Sie erhobenen Daten durch Google Maps Routenplaner in der zuvor
            beschriebenen Art und Weise und zu dem zuvor benannten Zweck
            einverstanden.
          </p>
          <h5>Auskunfts-und Widerrufsrecht</h5>
          <p>
            Ihre Datenschutz-Einwilligungserklärung können Sie jederzeit mit
            Wirkung für die Zukunft widerrufen. Bitte nutzen Sie dazu die weiter
            unten angegebenen Kontaktdaten
          </p>
          <h5>Unsere Kontaktdaten</h5>
          <p>
            Falls Sie Ihre Datenschutz-Einwilligungserklärung widerrufen
            möchten, Fragen zu dieser Datenschutzerklärung haben, z.B. zur
            Einsicht oder Aktualisierung Ihrer personenbezogenen Daten, so
            wenden Sie sich bitte an die folgende Adresse:
            <br />
            Paul Berenz Bedachungen GmbH
            <br />
            Industriering 21
            <br />
            56812 Cochem-Brauheck
          </p>
          Oder senden Sie eine E-Mail an info@paul-berenz.de
        </TextWrapper>
      </ServicesContainer>
    </Layout>
  )
}

export const getIndexData = graphql`
  query {
    geschichteImage: file(relativePath: { eq: "geschichte.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 4160) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`

const ServicesContainer = styled.section`
  padding-top: 3rem;

  .services_separator_margin {
    width: 20%;
    margin: 3rem auto;
  }

  .services_separator {
    width: 20%;
    margin: 0 auto;
  }
`

const TextWrapper = styled.div`
  margin-top: 1rem;
  padding: 1rem;

  @media (min-width: 780px) {
    padding: 4rem;
    margin-top: 4rem;
  }
`

export default Index
